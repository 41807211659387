/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  MultimodalLiveAPIClientConnection,
  MultimodalLiveClient,
} from "../lib/multimodal-live-client";
import { LiveConfig } from "../multimodal-live-types";
import { AudioStreamer } from "../lib/audio-streamer";
import { audioContext } from "../lib/utils";
import VolMeterWorket from "../lib/worklets/vol-meter";

export type UseLiveAPIResults = {
  client: MultimodalLiveClient;
  setConfig: (config: LiveConfig) => void;
  config: LiveConfig;
  connected: boolean;
  connect: () => Promise<void>;
  disconnect: () => Promise<void>;
  volume: number;
};

export function useLiveAPI({
  url,
  apiKey,
}: MultimodalLiveAPIClientConnection): UseLiveAPIResults {
  const client = useMemo(
    () => new MultimodalLiveClient({ url, apiKey }),
    [url, apiKey],
  );
  const audioStreamerRef = useRef<AudioStreamer | null>(null);

  const [connected, setConnected] = useState(false);
  const [config, setConfig] = useState<LiveConfig>({
    model: "models/gemini-2.0-flash-exp",
  });
  const [volume, setVolume] = useState(0);

  // register audio for streaming server -> speakers
  useEffect(() => {
    if (!audioStreamerRef.current) {
      audioContext({ id: "audio-out" }).then((audioCtx: AudioContext) => {
        audioStreamerRef.current = new AudioStreamer(audioCtx);
        audioStreamerRef.current
          .addWorklet<any>("vumeter-out", VolMeterWorket, (ev: any) => {
            setVolume(ev.data.volume);
          })
          .then(() => {
            // Successfully added worklet
          });
      });
    }
  }, [audioStreamerRef]);

  useEffect(() => {
    const onClose = () => {
      setConnected(false);
    };

    const stopAudioStreamer = () => audioStreamerRef.current?.stop();

    const onAudio = (data: ArrayBuffer) =>
      audioStreamerRef.current?.addPCM16(new Uint8Array(data));

    client
      .on("close", onClose)
      .on("interrupted", stopAudioStreamer)
      .on("audio", onAudio);

    return () => {
      client
        .off("close", onClose)
        .off("interrupted", stopAudioStreamer)
        .off("audio", onAudio);
    };
  }, [client]);

  const connect = useCallback(async () => {
    console.log(config);
    if (!config) {
      throw new Error("config has not been set");
    }
    client.disconnect();
    await client.connect(config);
    setConnected(true);

    client.send([
        {
          text: `Eres UniBot, el asistente virtual de la Universidad de Moreno. Ayudas a los empleado de la Universidad a responder diversas consultas. Siempre responde de manera clara, amable, profesional, corta y concisa.

            **IMPORTANTE:**  
            - Habla SIEMPRE en español con acento argentino.  
            - Todos los números, montos, cantidades y unidades de medida deben expresarse **siempre en español**, tanto en texto como en su pronunciación. Ejemplo: el interno 3503 es 'tres mil quinientos tres' o la UBICACIÓN con codigo 'A 102' es 'A ciento dos'.  
            - No utilices palabras, números ni unidades en inglés en ninguna circunstancia.  
            - Si no tienes la información solicitada, responde de manera honesta con frases como: 'La información no la tengo por el momento' o 'No dispongo de esos datos actualmente'.  
            - Si te preguntan algo que no esté relacionado con la Universidad o Universidad de Moreno, responde: 'La información la desconozco. Por favor, pregúntame sobre la Universidad o temas relacionados con la Universidad de Moreno'.
            - No reveles información sobre el lenguaje o modelo de IA utilizado. Ante esas preguntas, responde que fuiste modelado y diseñado por UniBot (Un desarrollo del área de sistemas de la universidad).
            - Si necesitas realizar cálculos matemáticos, verifica cuidadosamente cada operación. Un error en los cálculos puede generar problemas significativos, por lo que es fundamental evitar equivocaciones. 
            - NO ALUCINES. Siempre responde con información real y verificable. sI NO SABES ALGO, RESPONDE QUE NO DISPONES DE LA INFORMACIÓN.
            - Si te preguntan por el clima diras 'Parece un bello dia pero desde aqui dentro no puedo confirmarlo'
            - Si te preguntan por temas relacionados [fichadas; licencias; presentimos; sueldo; ] diras 'Aun no tengo la capacidad de conectarme a la Base de Datos de esta información'

            Si el tema consultado es sobre teléfonos internos o usuarios y sus arreas de trabajo a continuación te dejo un listado de los usuarios y sus áreas de trabajo:
            ÁREA	USUARIO	INTERNO	UBICACIÓN
            Rectorado	Hugo Andrade		Edificio central
            Dirección de Secretaría Privada y Ceremonial (R-DSPC)		3600 /310
            División de Coordinación Administrativa de Rectorado (R-DCAR)	Estela Susana Marfeo	106
            División de Relaciones Institucionales (SRII-DRI)	Nataly López Teliz	3138/111
            Coordinación Especial Programa de Planeamiento Estratégico y Política Universitaria (R-PPEPU)	Manuel Gómez	152	Edificio Central
            Flor Tuculet	3601	H 102	Edificio Histórico Modulo H. Planta primer piso	
            Vicerrectorado (R-VR)	Alejandro Robba		Edifcio Central
            Departamento de Coordinación Técnico-Operativa de Vicerrectorado (VR-DCTA)	Lucía Fernández	3610
            Antonella Montelpare	3106
            Trinidad Lo Cicero	3106
            División de Coordinación Administrativa de Vicerrectorado (DCTA-DCAV)	Johana Chacón	3610
            Dirección Académica Centro de Estudios de Gobierno y Políticas Públicas (R-CEGPP)	Pablo Tavilla	3620	A 102	Edificio Histórico Modulo A. Planta primer piso
            Coordinación Académica Programa de Estudios Fiscales		3602	H 102	Edificio Histórico Modulo H. Planta primer piso
            Coordinación Especial Programa de Posgrado (R-CEPP)	Florencia Gosparini	3600	H 103	Edificio Histórico Modulo H. Planta primer piso
            Pilar Acuña	3774
            Dirección General Planeamiento y Control de Gestión (R-DGPCG)	Florencia Gosparini	3600	H 103	Edificio Histórico Modulo H. Planta primer piso
            Trinidad Calandria	3774
            Verónica González	3774
            Analia Contreras	3779
            Dirección de Presupuesto (DGPCG-DGP)	Matías Buzzone	3622
            Dirección de Comunicación Institucional (R-DCI)	Pablo Penela	3162	D 002 - D 003	Edificio Histórico modulo D. Planta baja
            Fernando Albornoz	3006
            Sebastián Hermosa Acuña	3263
            Damián Fuentes	3263
            Gisela Cogo	3262
            Florencia Cuburu	3262
            Paula Micaela Repetto	3262
            María Noel Perez	3261
            Rolando Camarra	3260
            Johana Ciabattari	3260
            Leandro Irión	3260
            Departamento de Asuntos Editoriales (DCI-DAE)	Hugo Galiano	3154
            Departamento de Radio Universitaria (DCI-DRU)	Fabián Mazzoni	3145	F 002	Edificio Histórico modulo E. Planta baja
            Paula Bovone	3145
            Héctor Daniel Porcel	3150
            Julián Schamun	3145
            Mariela Elizabeth de la Hoz	3145
            Subsecretaría de Relaciones Internacionales e Institucionales (R-SRII)	Marcelo Monzón	124/133	G 102	Edificio Histórico Modulo G. Planta primer piso
            Gabriela Camacho	3231
            Marisa D'Amato	3231
            Unidad Auditoría Interna (R-UAI)	Roberto Padilla	3149	Edificio Central
            Kevin Gimenez	3149
            Subsecretaría de Consejo Superior (R-SCS)	Manuela Penela	3603	Rectorado	Edificio central
            Sala	426
            Secretaría Académica (VR-SAC)	Roxana Silvia Carelli	3148	G 201	Edificio Histórico Modulo G. Planta segundo piso
            Florencia Mariel Duquez	135
            Departamento de Gestión Docente (DGA-DGD)	Luciana Inés Suriani	3255
            María Paula Assis	3050
            Dirección de Orientación, Articulación e Ingreso (SAC-DAOI)	María Lorena Demitrio	3212	C 005	Edificio Histórico modulo E. Planta Baja
            Evelin Duarte	3211
            Melisa Rodríguez	3210
            Chorny	3210
            División de Orientación y Accesibilidad (DAOI-DOA)	Noelia Agustina Rodríguez	3213
            Departamento de Estudios y Asistencia Técnica (SAC-DEAT)	Graciela Noemí Ramos	3323	SUBSECRETARIA DE PLANIFICACION Y GESTION ACADEMICA
            Micaela Myriam Rositto	3323
            Departamento de Biblioteca y Centro de Documentación (SAC-DBCD)	Andrea Soledad Nagore	3200				
            María Guadalupe Bravo	3200				
            Jeny Consuelo Díaz Silva	3201				
            Romina Clarisa Lescano	3202				
            Departamento de Archivo de Legajos Estudiantil y Docente (DTO-DALED)	Rubén Ernesto Loscri	3252	C 003	Edificio Histórico modulo C. Planta baja
            Subsecretaría de Planificación y Gestión Académica (SAC-SPGA)	Milena Cevallos	3320	SUBSECRETARIA DE PLANIFICACION Y GESTION ACADEMICA	Edificio Histórico. Planta baja
            Romina Benitez	3130
            Graciela Patricia Stracquadaini	3051
            División de Títulos (SPGA-DT)	Joan Dodaro	3321
            Daniel Roberto Fernández	3321
            Dirección de Desarrollo Académico (SPGA-DDA)	Evangelina Cáceres	3319/3322
            Silvina Corniola	3319/3322
            Departamento de Alumnos (DGA-DA)	Ecila Echenique	3117	B 003	Edificio Histórico Modulo B. Planta baja
            Daniela Cecilia Ledesma	3250 / 117
            Matías Nahuel Samaniego	3250 / 117
            Matías Jonathan Javier Torres	3250
            Lucas Ariel Luján	3254
            Noelia Santillán	3256
            División de Coordinación Administrativa de Alumnos (DA-DCAA)	Andrea Viviana Kannemann	3117
            División de Programación de la Oferta Académica (DGA-DPOA)	Manuel Alejandro Ayala	3253	Edificio Histórico Modulo Central. Planta baja
            Maximiliano Zapata	3324
            Nazareth Ciabattari	3324
            Agustín Braticevic	
            Laura Etelvina Luna	3053
            Mónica Mariel Silva	3251
            Bedelía Edif. Histórico	3324
            Bedelia Daract 1	3053
            Bedelia Dorrego 1	3251
            Secretaría de Investigación y Vinculación Tecnológica (VR-SdIVT)	Adriana Sánchez	3634	B 102	Edificio Histórico Modulo B. Planta primer piso	
            Yasmina González	3123	B 103	
            Dirección General de Vinculación Tecnológica (SdIVT-DGVT)	Walter Klein	3230	A 103	Edificio Histórico Modulo A. Planta primer piso
            Gonzalo Rubio	3122
            Santiago Odriozola	3127
            Carlos Mailen	3123
            Dirección General de Investigación Científica (SdIVT-DGIC)	Marcela Basterrechea	3290	C 103	Edificio Histórico Modulo C. Planta primer piso
            Marianela Jauregui	3045
            Deborah Pane	3045
            Cyntia Induni	3045
            Subsecretaría de Recursos Humanos (VR-SRRHH)	Carlos Castro	3216	Edificio Histórico planta baja
            Dirección General de Recursos Humanos (SRRHH-DGRRHH)	Gustavo Ariel Almirón	3218
            Florencia Diaz	3217
            Lucas Esteban Antonio Fregenal	3217
            División de Legajos (DGRRHH-DL)	María Victoria García	3215
            Secretaría de Extensión Universitaria (VR-SEU)	Esteban Sánchez	3773	C 001	Edificio Histórico Modulo C. Planta baja
            División de Secretaría Privada de Extensión (SEU-DSPE)	María Guadalupe Balard	3775
            Dirección General de Extensión Universitaria (SEU-DGEU)	Olga Barrios	3776
            Monica Miglino	3775/3776
            Dirección de Políticas de Convivencia y Equidad de Género (SEU-DPCEG)	Natalia Kisman	3775/3776
            Camila Galiano	3775/3776
            Departamento de Bienestar y Asuntos Estudiantiles (SEU-DBAE)	Soledad Pique	3126	E 001	Edificio Histórico Modulo E. Planta baja
            Yanina Morandi	3126
            Diego Motta	3126
            Lucio Fardelli	3126
            Enfermería	Silvia Rosales	3777	E 003	Edificio Histórico Modulo E. Planta baja
            Marcela Andrada	3777
            Secretaría de Administración (VR-SdA)	Graciela Hage	3113	F 101	Edificio Histórico Modulo F. Planta primer piso	
            División de Secretaría Privada de Administración (SdA-DSPA)	Juan Manuel Alvarez	3157	
            Sol Tuculet	3221	
            Subsecretaría de Coordinación de Administración (SdA-SCA)	Marcela Paula Lamelza	3116	
            Dirección General Contable (SCA-DGC)	Lidia Gayo	3108			
            División de Registro Contable (DGC-DRC)	Rosa Soriano	3156			
            Dirección General de Compras y Contrataciones (SCA-DCC)	Vanesa Cattaeno	3169	F 201	Edificio Histórico Modulo F. Planta segundo piso
            Nicolás Pérez	3114
            Malen Debus	3118			
            Dirección de Licitaciones (DCC-DL)	Adolfo Puccio	3226			
            Subsecretaría de Coordinación Técnica (SdA-SCT)	Martin Monea	3167	F 101	Edificio Histórico Modulo F. Planta primer piso
            Maria Luisa Elizalde	3168
            Dirección de Coordinación Técnico Administrativa (SCT-DCTA)	Alejandro Barenthin	3220
            Marcela Ercoli	3220
            División de Registro Patrimonial (DCTA-DRP)	Pedro Barrios	3142			
            Mariano Lemongi	3140			
            Departamento de Liquidación de Haberes (SdA-DLH)	Lucia Peiper	227			
            Unidad de Gestión de Conrtol de Obras (SdA-UCGO)	Eduardo Suriani	3225	F 201	Edificio Histórico Modulo F. Planta segundo piso
            Dirección de Tesorería (SdA-DT)	Ana Maresca	3012	MESA DE ENTRADA
            Diego Rodriguez	3012
            Instituto Tecnológico de la Universidad Nacional de Moreno (R-ITUNM)	Julio Alvarez	3177	G 003	Edificio Histórico Modulo B. Planta baja
            Ana Crocsel	3178
            Secretaría Legal y Técnica (VR-SLT)	Guillermo Cony	3020	G 101	Edificio Histórico Modulo G. Planta primer piso
            Administración	Silvana Moncalieri	3172
            Dirección de Concursos (SLT-DC)	Emiliana Tomasello	3174
            Dirección General de Asuntos Jurídicos (SLT-DGAJ)	Martín Ariel Rodríguez	3170
            Yanina Bidart	3170
            Departamento de Mesa de entrada (SLT-DME)	Marisa Ameneiro	3503	MESA DE ENTRADA	Edificio Histórico. Planta baja
            Melisa Deparsia	3503
            División de Registro de Libros, Actas y Boletín Oficial (DME-DRLABO)	Beatriz Gayo	3500	B 004
            Secretaría de Tecnologías de la Información y Comunicación (VR-STIC)	Claudio Celenza	3302	
            Dirección de Sistemas de Información (STIC-DSI)	Nahuel Sánchez	3107				
            Javier Trillo	3107	Edificio Histórico Modulo Central. Planta segundo piso
            Facundo Garea	3107
            Julián Grimaux	3107				
            Dirección de Entornos Virtuales (STIC-DEV)	Luis Tuculet	3107				
            Soporte Técnico Daract I, II y Dorrego	Germán Alvarez	3005	Edificio Daract 1. Primer Piso

            Dirección de Infraestructura Tecnológica (STIC-DIT)	Juan Manuel Galiano	3010				
            Alejandro Carbone	3010		Edificio Histórico Modulo C. Planta primer piso
            Regino Sánchez	3010	C 102
            Administración	Agustín Daddario	3011				
            Secretaría de Infraestructura y Plan Maestro (VR-SIPM)	Myriam Pinto	3143	I 201	Edificio Histórico Modulo I. Planta segundo piso
            Germano Damian	3144
            Natalia Lorena Arizio	3147
            Diego Villagra	3121
            División de Formulación de Proyectos de Arquitectura (SIPM-DFPA)	Rodríguez Martin Ariel	3147
            Departamento de Fiscalización de Obras (SIPM-DFO)	Amaro Fabián	3146
            Dirección de Mantenimiento Edilicio y Seguridad (SIPM-DMES)	Mariana Díaz	3101	Pañol de Mantenimiento
            Celeste Miño	3120
            Laura Carra	3100
            División de Patrimonio Físico (DSGM-DPF)	Daniel Lehmann	3102
            División de Limpieza y Mantenimiento (DSGM-DLM)	Fabian Daddario	3100
            Dirección general de gestión ambiental, calidad e higiene y seguridad (SIPM-DGACHS)	Carlos Ignacio Cañas	3112	E 005	Edificio Histórico Modulo E. Planta baja
            Dirección de Higiene y Seguridad (DGACHS-DHS)	Héctor Peterson	3112
            Departamento de Laboratorios (DGACHS-DL)	Ruben Barragan	3139
            Polideportivo		3160				
            Pañol Llaves		3762				
            Garita de seguridad entrada Mitre		3631				
            Garita de seguridad entrada Corvalán		3630				
            Departamento de Ciencias Aplicadas y Tecnologías (DCAYT)	Maria Liliana Taramasso	3031		
            Dirección General de Gestión Académica (DCAYT-DGGA)	Graciela Ecenarro	3030				
            Dirección de Coordinación Técnica de Proyectos	Pablo Bordon	3029				
            Dirección de Gestión y Carrera Docente	Gustavo Alberto Benedetti	3029				
            División de Coordinación Administrativa	Judith Sosa	3129	B 203	Edificio Histórico Modulo C. Planta primer piso
            División de Secretaria Privada	Sonia Carosella	3129
            Administración	Camilo Villanueva	3129
            Lautaro Morelo	3129
            Wanda Mercado	3129
            Florencia Peranic	3129
            Coordinación Vicedecanato Carrera de Arquitectura (DCAYT-ARQ)	Daniel Etcheverry	3223	G 103	Edificio Histórico Modulo G. Planta primer piso
            3033
            Coordinación Vicedecanato Carrera de Lic. en Gestión Ambiental (DCAYT-LGA)	Marcela Alvarez	3768	G 104
            Beatriz Arias	3110				
            Coordinación Vicedecanato Carrera de Lic. en Biotecnología (DCAYT-LBT)	Fernando Raibenberg	3501	G 202	Edificio Histórico Modulo F. Planta segundo piso
            3502
            Juan Bisceglia	3502
            Coordinación Vicedecanato Carrera de Lic. en Ingeniería en Electrónica (DCAYT-IEL)	Pedro Giuffrida	3115	F 102	Edificio Histórico Modulo F. Planta primer piso
            Departamento de Humanidades y Ciencias Sociales (DHYCS)	Juan Martín Etcheverry	3040	D 101	Edificio Histórico Modulo D. Planta primer piso
            Lorena Pennini	3044
            Tomás Heredia	3044
            Gustavo Kantor	3041
            Bianca Salvi	3041
            Dirección de Gestión y Carrea Docente	Juliana Tomasello	3046
            División de Coordinación Administrativa	Adriana Noelia Saavedra	3043
            Dirección General de Gestión Académica (DHYCS-DGGA)	Victoria Sverdlick	3039
            Centro de Estudios de Medios	Adriana Speranza	3049	F 001	Edificio Histórico Modulo F. Planta baja
            Investigadores	3155
            Alexis Burgos	3047
            Centro de Estudios de Educación	Pablo Narvaja	3038				
            Coordinación Vicedecanato Carrera de Licenciatura en Trabajo Social (DHYCS-LTS)	Maia Klein	3770	D 005	Edificio Histórico Modulo D. Planta baja
            Coordinación Vicedecanato Carrera de Licenciatura en Área Educación (DHYCS-AE)	Juana Ferreyro	3772
            Coordinación Vicedecanato Carrera de Licenciatura en Comunicación Social (DHYCS-LCS)	Luis Alejandro Canepa	3771	E 006	Edificio Histórico Modulo E. Planta baja
            Departamento de Ciencias Económicas y Jurídicas (DCEYJ)	Marcelo Monzón	3621	A 101	Edificio Histórico Modulo A. Planta primer piso
            Martina Romano	3621
            Brenda Clivio	3621
            Noelia Ramos	3621
            Dirección General de Gestión Académica (DCEYJ-DGGA)	Sara Melo Tejada	3625
            Dirección Gestión y Carrera Docente (DGGA-DGCD)	Lucas Rago	3623
            Dirección de Coordinación Técnica de Proyectos (DGGA-DCTP)	Verónica García Allegrone	3624
            División de Coordinación Administrativa (DCEYJ-DCA)	Guadalupe De Paula	3619
            Coordinación Vicedecanato Carrera de Licenciatura en Administración (DCEYJ-LAD)	Leticia Patrucchi	3765
            Coordinación Vicedecanato Carrera de Licenciatura en Economía (DCEYJ-LEC)	Agustín Mario	3764	D 004	Edificio Histórico Modulo D
            Coordinación Vicedecanato Carrera de Licenciatura en Relaciones del Trabajo (DCEYJ-LRT)	Lara Yepes	3763
            Coordinación - Vicedecanato Carrera de Contador Público Nacional (DCEYJ-CPN)	Marcelo Cafferata Ferri	3766
            ESPUNM	Claudio Fardelli	5001				
            Guadalupe Ferraro	5000				
            Preceptoría	5002				
            Gabriela Ciz	5003				
            Administrativo	5004				
            Laboratorios	Laboratorio de Química Ambiental	3032	D 201	Edificio Histórico Modulo D. Planta segundo piso	
            Laboratorio de Electrónica		B 202	Edificio Histórico Modulo B. Planta segundo piso	
            Laboratorio de Física		A 202-203	Edificio Histórico Modulo A. Planta segundo piso	
            Laboratorio de Química		A 201	Edificio Histórico Modulo A. Planta segundo piso	
            Laboratorio de Química Orgánica		F 202	Edificio Histórico Modulo F. Planta segundo piso	
            Laboratorios de Informática		B 101	Edificio Histórico Modulo B. Planta primer piso	
            Edificio de Laboratorios	Ruben Nieto	3139				
            Ruben Barragan	3139				
            Micol Rocha Alarcón	3139				
            Ariadna Nieto	3139				
            `,
            },
        ]);

  }, [client, setConnected, config]);

  const disconnect = useCallback(async () => {
    client.disconnect();
    setConnected(false);
  }, [setConnected, client]);

  return {
    client,
    config,
    setConfig,
    connected,
    connect,
    disconnect,
    volume,
  };
}
